/*================================================================================
	Item Name: IVR - Market Place
	Version: 2.0
	Author: Abacies
	Author URL: #
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
[dir=ltr] .vertical-layout .main-menu .navigation a.active { background: linear-gradient(118deg, #20124A,  #462E95); box-shadow: 0 0 10px 1px rgba(#314ec4, 0.7); }

.main-menu .navbar-header .navbar-brand .brand-logo img { max-width: 100px; }
.menu-collapsed .main-menu .navbar-header .navbar-brand .brand-logo img { max-width: 45px; }
.menu-collapsed .main-menu .navbar-header .navbar-brand .brand-logo img + small { font-size: 7px; }

.form-select {
    background-position: right !important;
}
.campaign-card {
	border: 2px solid #462E95;
}
.campaign-card  p {
	color:#462E95
}
.card-text-log {
	color:#462E95 !important;
}
.dropzone {
	border:2px dashed #adadad;
	padding:2rem;
}
.react-tagsinput-tag{
	background-color: rgba(70, 46, 149, 0.16) !important ;
	color: rgba(70, 46, 149, 1) !important
}

.react-tagsinput--focused {
	border-color: #462E95 !important;
  }
.nested-card {
	background-color: rgba(70, 46, 149, 0.04);
}
.react-dataTable .rdt_TableHead .rdt_TableHeadRow {
    background-color: #f3f2f7;
    border-top: 1px solid;
    border-color: #ebe9f1;
	font-size: 12px;
	font-weight:bold;
	color:#6e6b7b;
	text-transform: uppercase;
 }
 .truncated-paragraph {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
  }
  .swal2-confirm {
	margin-right: 5px !important;
  }
//   .notification-dropdown{
// 	transform: translateY(-85px)  !important;
// }
.dashbord-table .rdt_Table {
	min-height: 260px !important;

}
.user-card {
	background-color:  #462E95;
	color:#FFFFFF !important;
}
.campaign-card {
	border:1px solid #462E95;
	background-color: #FFFFFF;
	color: #462E95;
}
.pause-icon {
	stroke: #462E95 !important ;
}
// background-color: linear-gradient(118deg, rgba(#cbc3e7, 1), rgba(#cbc3e7, 0.7));
// }
.auth-wrap {
	height:100vh;
	background: linear-gradient(118deg, rgba(#9488bc, 1), rgba(#9488bc, 0.7));
    background-repeat: no-repeat;
}
.card-success {
	background: linear-gradient(118deg, rgba(#462E95, 1), rgba(#462E95, 0.7));
    color: #fff;
	.avatar svg {
		height: 28px !important;
		width: 28px !important;
	  }
	  a {
		color: orange
	  }
}
.sign-up-wrap .auth-inner {
	max-width: 500px !important;
}
/////////payment////////////////////

.is-disable {
	opacity:0.5;
	pointer-events: none;
}
.FormGroup {
	flex:0 0 60%;
	margin: 0 15px 20px;
	padding: 0;
	border-style: none;
	// background-color: #7795f8;
	will-change: opacity, transform;
	// box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #829fff;
	border-radius: 4px;
	border: 1px solid #aaa;

}

.FormRow {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	margin-left: 15px;
}
.payment-btn-wrap {
	width:60%;
}
.StripeElement--webkit-autofill {
	background: transparent !important;
}

.StripeElement {
	width: 100%;
	padding: 11px 15px 11px 0;
}
.payment-success-text {
	h1, h2, h3, h4, h5, h6 {
	color:#fff !important;
	}
}
.custom-alert {
	background-color: #fff !important;
	color:#6e6b7b !important
}
.custom-ai-btn {
	cursor: pointer;
    padding: 0 0.5rem;
    border: 1px solid #6e6b7b;
    border-radius: 0.358rem;
}
.custom-badge {
	padding-top: 0.4rem;
	padding-bottom: 0.4rem;
}
.ticket-container  {
	 .ticket-cell {
		border: 1px solid #462E95;
	 }
	 .accordion-margin {
		border-radius:0.345rem;
		border-bottom: 1px solid #462E95 !important
	 }
}
.email-modal {
	max-height: 300px;
	overflow-y: auto;
}
.rdw-editor-wrapper{
	border:1px solid #dae1e7;
	border-radius: 0.5rem;
	margin-top: 20px;
}